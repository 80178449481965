import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initialize } from "./helpers/general";
import axios from "axios";
import VueAxios from "vue-axios";
import Vue3Storage from "vue3-storage";
import Multiselect from "vue-multiselect";
import VLazyImage from "v-lazy-image";
import VueBasicAlert from 'vue-basic-alert';

axios.defaults.baseURL = "https://www.admin.tourdeice.bike/api/";

initialize(store, router);
window.$ = window.jQuery = require("jquery");

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(Vue3Storage);
app.component("VLazyImage", VLazyImage);
app.component("VueBasicAlert", VueBasicAlert);
app.use(Multiselect);

app.mount("#app");
