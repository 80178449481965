<template>
	<!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/blog.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">Blog</h1>
				</div>
			</div>
		</div>
	</div>
    
    <!-- shop section start -->
    <!-- <div class="row pt-5" style="place-content: center;">
        <div class="container">
            <p class="h5" v-html="blog.content1"></p>
        </div>
    </div> -->
    <div class="cy_blog_wrapper cy_section_padding  cy_blog_page">
        <div class="container">
            <div class="row">

                <div class="col-lg-6 col-md-12" v-for="(blog, index) in blogs" :key="index">
                    <div class="cy_blog_box wow fadeInUp">
                        <div class="cy_blog_img">
                            <v-lazy-image :src="blog.thumbnail == null ? 'http://via.placeholder.com/570x361' : base+blog.thumbnail"
                            style="object-fit: cover;height:361px;width:100%" class="img-fluid" />
                            <div class="cy_blog_overlay"></div>
                        </div>
                        <div class="cy_blog_data">
                            <ul class="cy_blog_info">
                                <li><a>by {{blog.created_by}}</a></li>
                                <li><a>{{blog.date}}</a></li>
                                <li><a>{{ blog.keyword }}</a></li>
                            </ul>
                            <h2><router-link :to="'/blog/'+blog.id">{{ blog.title }}</router-link></h2>
                            <p>{{ blog.description }}</p>
                            <router-link :to="'/blog/'+blog.id" class="cy_button">read more</router-link>
                        </div>
                    </div>
                </div>
                
            </div>
            <!-- <div class="row">
                <div class="cy_pagination padder_top40">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li><a href="#" class="page_pre"><i class="fa fa-chevron-left" aria-hidden="true"></i></a></li>
                            <li><a href="#">1</a></li>
                            <li><a href="#" class="active">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#">4</a></li>
                            <li><a href="#">5</a></li>
                            <li><a href="#" class="page_next"><i class="fa fa-chevron-right" aria-hidden="true"></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'home-blog',
    data(){
        return{
            blogs: [],
            mute:false,
			language_strings : [],
			reslang: [],
			base: 'https://www.admin.tourdeice.bike',
            blog:{
                content1:''
            }
        }
    },
	created(){
		
    },

    mounted() {
        window.scrollTo(0, 0)
		if (!this.$storage) {
			this.$storage.setStorageSync('langSessionID', "en")
		}
		if(this.$storage.getStorageSync('langSessionID') == undefined){
			this.$storage.setStorageSync('langSessionID', "en")
		}

		this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
		var datalangs = this.$store.dispatch('languages', this.language_strings)

		datalangs.then(response => {
			this.reslang = response
		})
		.catch((error) => {
			console.log(error, 'Got nothing for this user except bad news.')
		})

        this.getData()
        // this.getDataNode(487, 'blog')
    },

    methods: {
        async getData() {
			window.scrollTo(0, 0)

            this.mute = true
			this.axios.get("blog/index")
            .then((response) => {
                this.blogs = response.data.data
                this.mute = false
            })
            .catch((error) => {
                console.log(error)
                this.mute = false
            })
		},

        getDataNode(id, param) {
            var dataNode = this.$store.dispatch("getNodeById", id)

            dataNode.then(response => {
                this.$data[param] = response.data
            }).catch((error) => {
                console.log(error, 'Got Nothing for this user except bad news')
            })
        },
    },
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>