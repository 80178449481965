<template>
    <!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/gear.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">Cart</h1>
				</div>
			</div>
		</div>
	</div>

    <!-- checkout section start -->
    <div class="cy_checkout_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12 offset-lg-2">
                    <div class="cy_checkout_box">
                        <ul id="progressbar">
                            <li :class="current_page == 1 ? 'active' : ''">Billing Details</li>
                            <li :class="current_page == 2 ? 'active' : ''">Payment</li>
                            <li :class="current_page == 3 ? 'active' : ''">Receipt</li>    
                        </ul>

                        <div v-show="current_page == 1" class="woocommerce-billing-fields">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" placeholder="First Name*" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" placeholder="Last Name*" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" placeholder="Phone*" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" placeholder="Email*" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <select class="form-control">
                                            <option>Country</option>
                                            <option>U.K.</option>
                                            <option>India</option>
                                            <option>Australia</option>
                                            <option>Japan</option>
                                            <option>Africa</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <select class="form-control">
                                            <option>State</option>
                                            <option>England</option>
                                            <option>Madhya Pradesh</option>
                                            <option>Victoria</option>
                                            <option>Hiroshima</option>
                                            <option>Liberia</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <select class="form-control">
                                            <option>City</option>
                                            <option>London</option>
                                            <option>Delhi</option>
                                            <option>Sydney</option>
                                            <option>Tokyo</option>
                                            <option>Arthington</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" placeholder="Pincode*" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea placeholder="Address*" class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group checkbox">
                                <label>Ship To This Address
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <button class="cy_button next" @click="changePage(2)">Next</button>
                        </div>

                        <div v-show="current_page == 2" class="woocommerce-checkout-payment">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="wc_payment_method payment_method_bacs">
                                        <div class="form-group">
                                            <label>Debit Card</label>
                                            <input type="text" class="form-control" placeholder="Card Holder Name">
                                            <input type="text" class="form-control" placeholder="card number">
                                            <input type="text" class="form-control" placeholder="Security Code">
                                            <div class="expiry_date">
                                                <label>Expiry Date</label>
                                                <select class="form-control">
                                                    <option>Mon</option>
                                                    <option>01</option>
                                                    <option>02</option>
                                                    <option>03</option>
                                                    <option>04</option>
                                                    <option>05</option>
                                                    <option>06</option>
                                                    <option>07</option>
                                                    <option>08</option>
                                                    <option>09</option>
                                                    <option>10</option>
                                                    <option>11</option>
                                                    <option>12</option>
                                                </select>
                                                <select class="form-control">
                                                    <option>Year</option>
                                                    <option>2017</option>
                                                    <option>2018</option>
                                                    <option>2019</option>
                                                    <option>2020</option>
                                                    <option>2021</option>
                                                    <option>2022</option>
                                                    <option>2023</option>
                                                    <option>2024</option>
                                                    <option>2025</option>
                                                    <option>2026</option>
                                                    <option>2027</option>
                                                    <option>2028</option>
                                                    <option>2029</option>
                                                    <option>2030</option>
                                                    <option>2031</option>
                                                    <option>2032</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="wc_payment_method payment_method_bacs">
                                        <div class="form-group">
                                            <label>Credit Card</label>
                                            <input type="text" class="form-control" placeholder="Card Holder Name">
                                            <input type="text" class="form-control" placeholder="card number">
                                            <input type="text" class="form-control" placeholder="Security Code">
                                            <div class="expiry_date">
                                                <label>Expiry Date</label>
                                                <select class="form-control">
                                                    <option>Mon</option>
                                                    <option>01</option>
                                                    <option>02</option>
                                                    <option>03</option>
                                                    <option>04</option>
                                                    <option>05</option>
                                                    <option>06</option>
                                                    <option>07</option>
                                                    <option>08</option>
                                                    <option>09</option>
                                                    <option>10</option>
                                                    <option>11</option>
                                                    <option>12</option>
                                                </select>
                                                <select class="form-control">
                                                    <option>Year</option>
                                                    <option>2017</option>
                                                    <option>2018</option>
                                                    <option>2019</option>
                                                    <option>2020</option>
                                                    <option>2021</option>
                                                    <option>2022</option>
                                                    <option>2023</option>
                                                    <option>2024</option>
                                                    <option>2025</option>
                                                    <option>2026</option>
                                                    <option>2027</option>
                                                    <option>2028</option>
                                                    <option>2029</option>
                                                    <option>2030</option>
                                                    <option>2031</option>
                                                    <option>2032</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="cy_button next" @click="changePage(3)">Pay</button>
                        </div>

                        <div v-show="current_page == 3" class="woocommerce-checkout-receipt">
                            <h1>THANK YOU FOR YOUR ORDER!</h1>
                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                            <a href="#" class="cy_button">Print Receipt</a>
                            <a href="#" class="cy_button">Email Receipt</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
		name: 'cart-page',
		data(){
            return {
                current_page: 1
            }
		},

		created() {

		},

		mounted() {  
            
		},

		methods: {
            changePage(page){
                this.current_page = page
            }
		},
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        }
    }
</script>