<template>

	<!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/gear.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">{{ product.ProductName }}</h1>
				</div>
			</div>
		</div>
	</div>

    <!-- shop section start -->
    <div class="cy_shop_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="cy_shop_single_page">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">

                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li v-for="(img,index) in product.images" :key="'indicator'+index" data-target="#carouselExampleIndicators" :data-slide-to="index" :class="index == 0 ? 'active' : ''"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div v-for="(img,index) in product.images" :class="index == 0 ? 'carousel-item active' : 'carousel-item'" :key="'img'+index">
                                            <v-lazy-image class="d-block w-100" :src="img" alt="" />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="summary entry-summary pt-2">
                                    <h1 class="product_title">{{ product.ProductName }}</h1>
                                    <h4 class="product-price-amount">{{product.ProductCurrency}}{{product.Custprice}}</h4>
                                    <p class="woocommerce-product-detail" v-html="product.SalesText"></p>
                                    
                                    <form class="form-inline" @submit.prevent="purchase(product)">

                                        <div class="input-group mb-2 mr-sm-2">
                                            <select class="form-control" id="formSize" v-model="size" required>
                                                <option value="" selected disabled hidden>Size</option>
                                                <option v-for="(val, index) in sizes" :key="index" :value="val.id" >{{val.name}}</option>
                                            </select>
                                        </div>

                                        <div class="input-group mb-2 mr-sm-2">
                                            <select class="form-control" id="formColor" v-model="color" required>
                                                <option value="" selected disabled hidden>Color</option>
                                                <option v-for="(val, index) in colors" :key="index" :value="val.id" >{{val.name}}</option>
                                            </select>
                                        </div>

                                        <div class="input-group mb-2 mr-sm-2">
                                            <input type="number" min="1" class="form-control" id="formQuantity" placeholder="Quantity" v-model="quantity" required>
                                        </div>

                                        <div class="quantity">
                                            <button type="submit" class="cy_button">Add To Cart</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 pt-5">
                                <div class="woocommerce-tabs">
                                    <ul class="tabs">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#discription"  data-toggle="tab">Description</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div role="tabpanel" class="tab-pane fade show active" id="discription">
                                            <h3>Description</h3>
                                            <p class="woocommerce-product-detail" v-html="product.ProductText"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <router-link to="/gears" class="cy_button">Back To List Page</router-link>
                </div>
                
            </div>
        </div>
        <vue-basic-alert 
            :duration="500" 
            :closeIn="3000"
        ref="alert" />
    </div>
</template>

<script>
// import subContent from './subContentNew.vue';

export default {
	name: 'product-content',
	// components: { subContent},
	data() {
		return {
			product: {
                images:[],
                ProductName:'',
                ProductCurrency:'',
                Custprice:'',
                TeaserText:'',
                SalesText:'',
                ProductText:''
            },
			base: 'https://www.admin.tourdeice.bike',
			loading: true,
            quantity: '',
            size:'',
            color:'',
            sizes: [
                {
                    id:1,
                    name:'Dummy 1'
                },
                {
                    id:2,
                    name:'Dummy 2'
                },
                {
                    id:3,
                    name:'Dummy 3'
                }
            ],
            colors: [
                {
                    id:1,
                    name:'Dummy 1'
                },
                {
                    id:2,
                    name:'Dummy 2'
                },
                {
                    id:3,
                    name:'Dummy 3'
                }
            ]
		}
	},
	mounted() {
		this.getdata();
	},
	methods: {
		async getdata() {
			
			window.scrollTo(0, 0)

            this.mute = true
			this.axios.get("get-product/"+this.$route.params.id)
            .then((response) => {
                this.product = response.data.data
                this.mute = false
            })
            .catch((error) => {
                console.log(error)
                this.mute = false
                this.$router.push({ name: 'NotFound' })
            })
		},

        purchase(product){
            let form = product
            form.quantity = this.quantity
            form.color = this.color
            form.size = this.size

            this.$store.dispatch('addToCart', form)
            
            this.$refs.alert.showAlert(
                'info',
                'Success add to cart',
                'Info'
            )

            this.quantity = ''
            this.color = ''
            this.size = ''
        }
	},
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>