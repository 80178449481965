<template>
	<div id="demo" class="carousel slide" data-ride="carousel">
		<ul class="carousel-indicators">
			<li data-target="#demo" data-slide-to="0" class="active"></li>
			<li data-target="#demo" data-slide-to="1"></li>
			<li data-target="#demo" data-slide-to="2"></li>
		</ul>
		<div class="carousel-inner">
			<div class="carousel-item active">
			<img src="/img/bg-1.jpg" alt="Los Angeles" width="1100" height="500">
			<div class="carousel-caption">
				<h1 style="text-shadow: 1px 1px 4px #FFF;">Greenland 2025</h1>
				<p style="font-size:1.2em">Be the first to cross Greenland on a bike!</p>
			</div>   
			</div>
			<div class="carousel-item">
			<img src="/img/bg-2.jpg" alt="Chicago" width="1100" height="500">
			<div class="carousel-caption">
				<h1 style="text-shadow: 1px 1px 4px #FFF;">Greenland 2025</h1>
				<p style="font-size:1.2em">Be an Arctic legend!</p>
			</div>   
			</div>
			<div class="carousel-item">
			<img src="/img/bg-3.jpg" alt="New York" width="1100" height="500">
			<div class="carousel-caption">
				<h1 style="text-shadow: 1px 1px 4px #FFF;">Greenland 2025</h1>
				<p style="font-size:1.2em">Do something no one else has done!</p>
			</div>   
			</div>
		</div>
		<a class="carousel-control-prev" href="#demo" data-slide="prev">
			<span class="carousel-control-prev-icon"></span>
		</a>
		<a class="carousel-control-next" href="#demo" data-slide="next">
			<span class="carousel-control-next-icon"></span>
		</a>
	</div>
	<!--About section start-->
	<div class="cy_about_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-12">
                    <div class="cy_about_img">
                        <img src="/img/hor-1.jpg" style="max-width: 85%;" alt="about" class="img-fluid" />
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="cy_about_data">
                        <h1 class="cy_heading">ABOUT US</h1>
                        <p v-if="menu.length > 0" v-html="aboutus.content1"></p>

                        <button type="button" v-show="showmore == false" @click="showmore = true" class="cy_button">Read More...</button>
                        <p v-if="menu.length > 0" v-show="showmore" v-html="aboutus.content2"></p>
                        <p v-if="menu.length > 0" v-show="showmore" v-html="aboutus.content3"></p>
                        <p v-if="menu.length > 0" v-show="showmore" v-html="aboutus.content4"></p>
                        
                        <br/>
                        <a href="https://www.strava.com/clubs/1116881/group_events/1712572" target="_blank" class="btn-block text-center cy_button">
                            Register your interest in joining now
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Team section start-->
    <div class="cy_team_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h1 class="cy_heading">meet our team</h1>
                </div>
            </div>
            <div class="row">

                <div v-for="(amb,index) in ambassadors" :key="index" class="col-lg-3 col-md-6">
                    <div class="cy_team_box">
                        <v-lazy-image :src="amb.medianode1 == null ? 'http://via.placeholder.com/270x377' : base + amb.medianode1" alt="team" class="img-fluid" />
                        <div class="cy_team_overlay">
                            <div class="cy_team_text">
                                <h2><a>{{ amb.text }}</a></h2>
                                <p>{{ amb.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--Achievements section start-->
    <div class="cy_achieve_wrapper">
        <div class="cy_achieve_overlay"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="cy_achieve_img wow cy_anim_left">
                        <img src="/img/ai-1.jpg" alt="achievement" class="img-fluid" />
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 cy_counter_wrapper">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="cy_heading">Tour De Ice 25</h1>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="cy_count_box">
                                <img src="images/svg/miles-driven.svg" alt="counter">
                                <h1 class="cy_counter_num" data-to="550" data-speed="1500">550</h1>
                                <p>KM Across Greenland</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="cy_count_box">
                                <img src="images/svg/club-members.svg" alt="counter">
                                <h1 class="cy_counter_num" data-to="2" data-speed="1500"></h1>
                                <p>Limited Number of Participants</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="cy_count_box">
                                <img src="images/svg/award-won.svg" alt="counter">
                                <h1 class="cy_counter_num" data-to="2" data-speed="1500"></h1>
                                <p>Be The First Male/Female To Succeed</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Gallery section css start-->
    <div class="cy_gallery_wrapper cy_section_padding padder_bottom70">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h1 class="cy_heading">Our gallery</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="cy_gal_img">
                        <img src="/img/galery/gal-1.jpg" alt="gallery" class="img-fluid" />
                        
                    </div>
                    <div class="cy_gal_img">
                        <img src="/img/galery/gal-2.jpg" alt="gallery" class="img-fluid" />
                        
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="cy_gal_img">
                        <img src="/img/galery/gal-5.jpg" alt="gallery" class="img-fluid" />
                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-mg-6 col-md-6">
                            <div class="cy_gal_img">
                                <img src="/img/galery/gal-3.jpg" alt="gallery" class="img-fluid" />
                                
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="cy_gal_img">
                                <img src="/img/galery/gal-4.jpg" alt="gallery" class="img-fluid" />
                                
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="cy_gal_img">
                                <img src="/img/galery/gal-6.jpg" alt="gallery" class="img-fluid" />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Cycle Tour section start-->
    <div class="cy_tour_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="cy_tour_heading">
                        <h1><a href="https://www.strava.com/clubs/tourdeice" target="_blank">join our club on STRAVA</a></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!--Our sponsors section start-->
    <div class="cy_sponsors_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="cy_ride_text cy_section_padding">
                        <h3>Tour De Ice 25 Start at</h3>
                        <h2>August 16, 2025</h2>
                        <router-link to="/ctc-bike" class="cy_button">read more</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--upcoming races section Start-->
    <div class="cy_races_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h1 class="cy_heading">Our partners and sponsors</h1>
                </div>
            </div>
            <div class="row">

                <div v-for="(par,index) in partners" :key="index" class="col-lg-6 col-md-12">
                    <div class="cy_races_box row">
                        <div class="cy_races_img col-md-6">
                            <center><v-lazy-image style="max-height: 7rem;" :src="par.image == null ? 'http://via.placeholder.com/215x211' : base+par.image" alt="races" class="img-fluid"/></center>
                        </div>
                        <div class="cy_races_data col-md-6">
                            <h2 v-if="par.WWW != null"><a :href="par.WWW" target="_blank">{{  par.CompanyName }}</a></h2>
                            <h2 v-else>{{  par.CompanyName }}</h2>
                            <ul v-if="par.City != null">
                                <li><img src="/images/svg/map-mark.svg">{{ par.City }}</li>
                            </ul>
                            <p v-html="par.Status"></p>
                            <a :href="par.WWW" target="_blank" class="cy_button">read more</a>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <!--result section start-->
    <div class="cy_result_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="cy_result_slider">
                        <h4 class="cy_heading">Nobody Have Ever Managed To Cross Greenland On A Bike</h4>
                        <br/>
                        <h4 class="cy_heading">Join us and be an Arctic Legend</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Store section start-->
    <div class="cy_store_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h1 class="cy_heading">
                        Find your perfect winter bike equipment in our store on 
                        <a href="https://www.shop.tourdeice.bike/" target="_blank">www.shop.tourdeice.bike</a>
                    </h1>
                </div>
            </div>
            
        </div>
    </div>
    <!--Prices section start-->
    <div class="cy_price_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h1 class="cy_heading">fee structure Tour De Ice 25</h1>
                </div>
            </div>
            <div class="row">

                <div class="col-lg-6 col-md-12" v-for="(pro, index) in products" :key="index">
                    <div class="cy_price_box">
                        <div class="cy_price_head">
                            <h3>{{ pro.ProductName }}</h3>
                        </div>
                        <div class="cy_price_body">
                            <div v-html="pro.FeatureText"></div>
                            <div class="cy_price_btn">
                                <router-link :to="'/product/'+pro.ProductID" class="cy_button">Read More</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <!--Blog section start-->
    <div class="cy_blog_wrapper cy_section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h1 class="cy_heading">latest from blog</h1>
                </div>
            </div>
            <div class="row">

                <div class="col-lg-6 col-md-12" v-for="(blog,index) in latest_blogs" :key="index">
                    <div class="cy_blog_box">
                        <div class="cy_blog_img">
                            <v-lazy-image :src="blog.thumbnail == null ? 'http://via.placeholder.com/570x361' : base+blog.thumbnail" class="img-fluid"
                            style="object-fit: cover;height:361px;width:100%"/>
                            <div class="cy_blog_overlay"></div>
                        </div>
                        <div class="cy_blog_data">
                            <ul class="cy_blog_info">
                                <li><a>by {{blog.created_by}}</a></li>
                                <li><a>{{blog.date}}</a></li>
                                <li><a>{{blog.keyword}}</a></li>
                            </ul>
                            <h2><router-link :to="'/blog/'+blog.id">{{blog.title}}</router-link></h2>
                            <p>{{blog.description}}</p>
                            <router-link :to="'/blog/'+blog.id" class="cy_button">read more</router-link>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'home-page',
    data(){
        return{
            datahome:{},
            mute:false,
			language_strings : ['idHp1','idHp63','idHp65','idHp68','idHp69','idHp77','idHp78', 'idHp79', 'idHp80','idSelectType',
			'idSelectCountry','idTypeSelected','idCountrySelected','idAdvanceSearch','idLS1','idLS2','idLSC1','idLSC2','idLSC3','idLSC4','idLSC5',
			'idLSC6','idTC1','idTC2','idInis1','idInis1a','idInis2','idInis2a','idInis3','idInis3a','idNAdd3',
			'idNAdd4','idNAdd5','idNAdd6','idNAdd7','idNAdd8'],
			reslang: [],
            menu:[],
            ambassadors:[],
            partners:[],
            products:[],
            latest_blogs:[],
            aboutus:{
                content1:'',
                content2:'',
                content3:'',
                content4:''
            },
            showmore: false,
            
			base: 'https://www.admin.tourdeice.bike',
        }
    },
	created(){
		
    },

    mounted() {
		if (!this.$storage) {
			this.$storage.setStorageSync('langSessionID', "en")
		}
		if(this.$storage.getStorageSync('langSessionID') == undefined){
			this.$storage.setStorageSync('langSessionID', "en")
		}

		// this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
		// var datalangs = this.$store.dispatch('languages', this.language_strings)

		// datalangs.then(response => {
		// 	this.reslang = response
		// })
		// .catch((error) => {
		// 	console.log(error, 'Got nothing for this user except bad news.')
		// })

        this.getData()
        this.getProduct()
        this.getDataNode(12, "menu")
        this.getDataNode(365, "ambassadors")
        this.getLatestBlog()
    },

    methods: {
        getData(){
            window.scrollTo(0, 0)

            this.axios.get('companies/getclassification/internal')
            .then((response) => {
                this.partners = response.data.company
			})
			.catch((error)=>{
				console.log(error, 'Got nothing for this user except bad news')
			})
        },

        async getProduct() {
			window.scrollTo(0, 0)

			this.axios.get("product/getclassification/competition")
            .then((response) => {
                this.products = response.data.data
            })
            .catch((error) => {
                console.log(error)
            })
		},

        getLatestBlog() {
			window.scrollTo(0, 0)

			this.axios.get("blog/latest")
            .then((response) => {
                this.latest_blogs = response.data.data
            })
            .catch((error) => {
                console.log(error)
            })
		},

        getDataNode(id, param) {
            let lang = this.$storage.getStorageSync('langSessionID')
            this.$store.commit('setLang', lang)
            var dataNode = this.$store.dispatch("getNodes", id)

            dataNode.then(response => {
                this.$data[param] = response.data
                if(id == 12){
                    for(let data_menu in response.data){
                        if(response.data[data_menu]['alias'] == "page/about"){
                            this.aboutus = response.data[data_menu]
                        }
                    }
                }
            }).catch((error) => {
                console.log(error, 'Got Nothing for this user except bad news')
            })
        },
    },
     watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>

<style>
	/* Make the image fully responsive */
	.carousel-inner img {
		width: 100%;
		height: 100%;
	}
  </style>